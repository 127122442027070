import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./AddUser.scss";

const EditUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { client } = location.state || {};

  const [formData, setFormData] = useState({
    contractNum: "",
    clientName: "",
    project: "",
    phone: "",
    mailId: "",
    emiratesId: "",
    contractStartDate: "",
    contractEndDate: "",
    insurancePremium: "",
    lastEdit: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (client) {
      setFormData({
        contractNum: client.client_contract_number || "",
        clientName: client.client_name || "",
        project: client.project || "",
        phone: client.client_number || "",
        mailId: client.client_email_id || "",
        emiratesId: client.client_passport || "",
        contractStartDate: client.client_contract_start_date || "",
        contractEndDate: client.client_contract_end_date || "",
        insurancePremium: client.client_insurance_status || "",
        lastEdit: client.last_edit || "",
      });
    }
  }, [client]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    try {
      const response = await axios.post(
        `https://lunarsenterprises.com:4000/aims/clients/update/${client.client_id}`,
        {
          client_id: client.client_id,
          client_contract_number: formData.contractNum,
          client_name: formData.clientName,
          client_number: formData.phone,
          client_email_id: formData.mailId,
          client_passport: formData.emiratesId,
          client_contract_start_date: formData.contractStartDate,
          client_contract_end_date: formData.contractEndDate,
          client_insurance_status: formData.insurancePremium,
        }
      );
      if (response.status === 200) {
        setSuccess("Client updated successfully.");
        setTimeout(() => navigate("/clients"), 2000); // Redirect after 2 seconds
      }
    } catch (error) {
      console.error("Error updating client:", error);
      setError("Failed to update client. Please try again.");
    }
  };

  return (
    <Container className="add-user-container">
      <h2 className="adduser">Edit Client</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formContractNum">
              <Form.Label>Contract Number</Form.Label>
              <Form.Control
                type="text"
                name="contractNum"
                value={formData.contractNum}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formClientName">
              <Form.Label>Client Name</Form.Label>
              <Form.Control
                type="text"
                name="clientName"
                value={formData.clientName}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formProject">
              <Form.Label>Project</Form.Label>
              <Form.Control
                type="text"
                name="project"
                value={formData.project}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formMailId">
              <Form.Label>Mail ID</Form.Label>
              <Form.Control
                type="email"
                name="mailId"
                value={formData.mailId}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formEmiratesId">
              <Form.Label>Emirates ID/Passport ID</Form.Label>
              <Form.Control
                type="text"
                name="emiratesId"
                value={formData.emiratesId}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formContractStartDate">
              <Form.Label>Contract Start Date</Form.Label>
              <Form.Control
                type="date"
                name="contractStartDate"
                value={formData.contractStartDate}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formContractEndDate">
              <Form.Label>Contract End Date</Form.Label>
              <Form.Control
                type="date"
                name="contractEndDate"
                value={formData.contractEndDate}
                onChange={handleChange}
                required
              />
            </Form.Group>

            <Form.Group controlId="formInsurancePremium">
              <Form.Label>Insurance Premium</Form.Label>
              <Form.Control
                type="text"
                name="insurancePremium"
                value={formData.insurancePremium}
                onChange={handleChange}
                required
              />
            </Form.Group>

            {/* <Form.Group controlId="formLastEdit">
              <Form.Label>Last Edit</Form.Label>
              <Form.Control
                type="text"
                name="lastEdit"
                value={formData.lastEdit}
                onChange={handleChange}
                required
                disabled
              />
            </Form.Group> */}
          </Col>
        </Row>
        <Button variant="primary" type="submit" className="mt-4">
          Update
        </Button>
      </Form>
    </Container>
  );
};

export default EditUser;
