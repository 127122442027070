import React from 'react';
import './Activity.scss';
import Table from 'react-bootstrap/Table';


const Activity = () => {
  return (
    <>
    <div className="activity-head">
      <h3>Activity Logs</h3>
    </div>
    <div className="date-search">
      <div className='dates'>
      <div className="start-date">
        <h5>From</h5>
        <input type="date" />
      </div>
      <div className="start-date">
        <h5>To</h5>
      <input type="date" />
      </div>
      </div>
      <button>Search</button>
    
    </div>
    <div className='sub-head'>
        <div className='head-icons'>
          <i className="fas fa-upload"> export</i>
          <i className="fas fa-download">import</i>
        </div>
      </div>
      <Table striped bordered hover variant="light">
          <thead>
            <tr>
              <th>S.NO</th>
              <th>ACTIVITY</th>
              <th>ACTION</th>
              <th>MODIFIED BY ID</th>
              <th>DATE OF CHANGE</th>
              <th>UPDATES</th>
              <th>UPDATED BY</th>
             
           </tr>
           
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              
            </tr>
          </tbody>
        </Table>
    </>
  );
}

export default Activity;
