import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.scss";
import { NavLink } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import Profile from "../images/profile.png";
import Facebook from "../images/fb.png";
import Google from "../images/google.png";
import Loginimg from "../images/login-img.png";

const Login = ({ handleLogin, handleLogout }) => {
  const [data, setData] = useState({
    user_email: "",
    user_password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [user_email, setUserEmail] = useState("");
  const [user_password, setUserPassword] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    if (savedEmail && savedPassword) {
      setUserEmail(savedEmail);
      setUserPassword(savedPassword);
    }
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  //................LOGIN Api...............//
  const handleSignInClick = async (event) => {
    event.preventDefault();

    if (user_email.trim() === "" || user_password.trim() === "") {
      setError("Email and password are required.");
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
      return;
    }

    if (!validateEmail(user_email)) {
      setError("Please enter a valid email address.");
      setShowMessage(true);
      setTimeout(() => setShowMessage(false), 3000);
      return;
    }

    try {
      const response = await fetch(
        "https://lunarsenterprises.com:4000/aims/users/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ user_email, user_password }),
        }
      );

      const data = await response.json();

      if (response.ok && data.result) {
        localStorage.setItem("email", user_email);
        localStorage.setItem("password", user_password);
        localStorage.setItem("token", data.token);
        console.log(data.token, "tokennnnn");
        console.log(data.user, "userrrrrr");

        // Show OTP modal after successful login
        setShowOtp(true);

        handleLogin();
      } else {
        setError(data.message);
        setShowMessage(true);
        document
          .querySelectorAll("input")
          .forEach((input) => (input.style.border = "1px solid red"));
        setTimeout(() => setShowMessage(false), 3000);
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  //................FORGOT PASSWORD Api.............//
  const handleForgotPassword = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/users/forgot-password",
        { user_email: forgotEmail }
      );
      console.log(forgotEmail, "Responseeee");
      console.log(response.data.message, "Message");
      alert(response.data.message);
      setShowForgotPasswordModal(false);
      setShowOtp(true);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  //.............OTP VERIFICATION API...........//
  const handleOtpVerify = async () => {
    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/verify",
        { email: user_email, token: otp }
      );
      console.log(response.data, "Responseeee");
      console.log(response.data.message, "Message");
      alert(response.data.message);

      if (response.data.success) {
        setShowOtp(false);
        setShowPasswordResetModal(true);
      } else {
        alert("OTP verification failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  //.............PASSWORD RESET API...........//
  const handlePasswordReset = async () => {
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/users/change_password",
        {
          user_email: user_email,
          password: newPassword,
          ConfirmPassword: confirmPassword,
        }
      );
      console.log(response.data, "Responseeee");
      console.log(response.data.message, "Message");
      alert(response.data.message);
      setShowPasswordResetModal(false);
      handleLogout();
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div className="login">
      <img src={Loginimg} alt="hello" />
      <div className="form-inputs">
        <img src={Profile} alt="" />
        <h2>Login</h2>

        <form onSubmit={handleSignInClick}>
          <div className="inputs">
            <label htmlFor="user_email">Email</label>
            <input
              type="text"
              className="form-control login-input"
              placeholder="Enter Email"
              aria-label="Enter User Name"
              value={user_email}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </div>

          <div className="inputs">
            <label htmlFor="user_password">Password</label>
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                className="form-control login-input"
                placeholder="Enter Password"
                aria-label="Enter Password"
                value={user_password}
                onChange={(e) => setUserPassword(e.target.value)}
              />
              <i
                className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                onClick={togglePasswordVisibility}
              ></i>
            </div>
          </div>

          {error && (
            <p className="error" style={{ color: "red" }}>
              {error}
            </p>
          )}

          <div
            onClick={() => setShowForgotPasswordModal(true)}
            style={{ cursor: "pointer", color: "black" }}
          >
            <h6>Forgot Password?</h6>
          </div>
          <div className="button">
            <button type="submit">Log In</button>
          </div>
          <h6>
            Don't Have An Account?{" "}
            <span>
              <NavLink to="/sign-up">Sign Up</NavLink>
            </span>
          </h6>
          <h5>or continue with</h5>
          <div className="links">
            <img src={Google} alt="" />
            <img src={Facebook} alt="" />
          </div>
        </form>
      </div>

      <Modal
        show={showForgotPasswordModal}
        onHide={() => setShowForgotPasswordModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={forgotEmail}
                onChange={(e) => setForgotEmail(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowForgotPasswordModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleForgotPassword}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showOtp} onHide={() => setShowOtp(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formOtp">
              <Form.Label>OTP</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter 6-digit OTP"
                value={otp}
                onChange={handleOtpChange}
                maxLength={6}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowOtp(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleOtpVerify}>
            Verify
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showPasswordResetModal}
        onHide={() => setShowPasswordResetModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNewPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="formConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowPasswordResetModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handlePasswordReset}>
            Change Password
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Login;
