import React, { useEffect, useState } from "react";
import "./clients.scss";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Clients = () => {
  const navigate = useNavigate();

  useEffect(() => {
    ClientList();
  }, []);

  const [list, setList] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // Add state for search input

  const ClientList = async () => {
    try {
      const response = await axios.get(
        "https://lunarsenterprises.com:4000/aims/clients"
      );
      setList(response.data);
      setFilteredClients(response.data);
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  const handleAddUser = () => {
    navigate("/adduser");
  };

  const handleEditUser = (client) => {
    navigate("/edituser", { state: { client } });
  };

  const DeleteApi = async (clientId) => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `https://lunarsenterprises.com:4000/aims/clients/delete/${clientId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`, 
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        alert(responseData.msg);
        ClientList(); // Refresh the client list after deletion
      } else {
        const errorData = await response.json();
        console.error("Failed to delete client:", errorData.message || errorData);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Handle search
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase(); // Get the search term in lowercase
    setSearchTerm(value);

    // Filter clients based on the search term (name matching)
    const filtered = list.filter((client) =>
      client.client_name.toLowerCase().includes(value)
    );

    setFilteredClients(filtered); // Update the filtered clients list
  };

  return (
    <>
      <div className="sub-head">
        <div className="head-icons">
          <i className="fas fa-upload"> export</i>
          <i className="fas fa-download">import</i>
        </div>
        <div className="search-actions">
          <input
            type="text"
            placeholder="Search by name"
            value={searchTerm} // Bind input value to searchTerm state
            onChange={handleSearch} // Handle search on input change
          />
          <button onClick={handleAddUser}>
            ADD CLIENT
            <i className="fas fa-plus"></i>
          </button>
        </div>
      </div>
      <Table striped bordered hover variant="light">
        <thead>
          <tr>
            <th>SL NO:</th>
            <th>CONTRACT NO</th>
            <th>NAME</th>
            <th>PHONE NUMBER</th>
            <th>EMAIL ID</th>
            <th>EMIRATES ID/PASSPORT NO:</th>
            <th>CONTRACT START DATE</th>
            <th>CONTRACT END DATE</th>
            <th>INSURANCE PREMIUM</th>
            <th>PROJECT</th>
            <th>CLIENT STATUS</th>
            <th>ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {filteredClients?.map((client, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{client.client_contract_number}</td>
              <td>{client.client_name}</td>
              <td>{client.client_number}</td>
              <td>{client.client_email_id}</td>
              <td>{client.client_passport}</td>
              <td>{client.client_contract_start_date}</td>
              <td>{client.client_contract_end_date}</td>
              <td>{client.client_insurance_status}</td>
              <td>Project name</td>
              <td>{client.client_status}</td>
              <td>
                <div style={{ display: "flex", gap: "10px" }}>
                  <i
                    className="fas fa-edit"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEditUser(client)}
                  ></i>
                  <i
                    className="fas fa-trash"
                    style={{ cursor: "pointer" }}
                    onClick={() => DeleteApi(client.client_id)}
                  ></i>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Clients;
