import React, { useState } from "react";
import "./Sign-up.scss";
import { NavLink, useNavigate } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [input, setInput] = useState({
    user_name: "",
    user_email: "",
    user_password: "",
    confirm_password: "",
  });

  const onHandleChange = (e) => {
    const { id, value } = e.target;
    setInput((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const createUser = async (e) => {
    e.preventDefault();
    if (
      !input.user_name ||
      !input.user_email ||
      !input.user_password ||
      !input.confirm_password
    ) {
      alert("Please enter all fields");
    } else if (input.user_password !== input.confirm_password) {
      alert("Confirm password does not match with password");
    } else {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:4000/aims/users/create",
          input
        );
        console.log(response);
        alert(response.data.message);
        navigate("/");
      } catch (error) {
        console.error(error);
        alert("Failed to create user. Please try again.");
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login">
      <img src="./login-img.png" alt="hello" />
      <div className="form-input">
        <h2>Sign up</h2>
        <form onSubmit={createUser}>
          <div className="input">
            <label htmlFor="user_name">Name</label>
            <input
              type="text"
              value={input.user_name}
              onChange={onHandleChange}
              name="user_name"
              id="user_name"
              placeholder="Enter your first name"
            />
          </div>
          <div className="input">
            <label htmlFor="user_email">Email</label>
            <input
              type="email"
              value={input.user_email}
              onChange={onHandleChange}
              name="user_email"
              id="user_email"
              placeholder="Enter your email"
            />
          </div>
          <div className="input">
            <label htmlFor="user_password">Password</label>
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                name="user_password"
                value={input.user_password}
                onChange={onHandleChange}
                id="user_password"
                placeholder="Enter your password"
              />
              <i
                className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`}
                onClick={togglePasswordVisibility}
              ></i>
            </div>
          </div>
          <div className="input">
            <label htmlFor="confirm_password">Confirm Password</label>
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"}
                value={input.confirm_password}
                onChange={onHandleChange}
                name="confirm_password"
                id="confirm_password"
                placeholder="Confirm your password"
              />
            </div>
          </div>
          <div className="button">
            <button type="submit">Sign up</button>
          </div>
          <h6>
            Already Have An Account?{" "}
            <span>
              <NavLink to="/">Log in</NavLink>
            </span>
          </h6>
          <h5>or continue with</h5>
          <div className="links">
            <img src="./google.png" alt="" />
            <img src="./fb.png" alt="" />
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Signup;
