import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import "./AddBank.scss";
import { useNavigate } from "react-router-dom";

const AddBank = () => {
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [wireNo, setWireNo] = useState("");
  const [insuranceAmount, setInsuranceAmount] = useState("");
  const [firstPayout, setFirstPayout] = useState("");
  const [due, setDue] = useState("");

  const navigate = useNavigate();

  const Add_Bank = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://lunarsenterprises.com:4000/aims/add-bank",
        {
          bank_name: bankName,
          branch_name: branchName,
          account_no: accountNo,
          ifsc_code: ifscCode,
          account_holder_name: accountHolderName,
          wire_no: wireNo,
          insurance_amount: insuranceAmount,
          first_payout: firstPayout,
          due: due,
        }
      );

      console.log(response.data.message, "Message");
      alert(response.data.message);
      navigate("/bank");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <Container className="add-user-container">
      <h2 className="adduser">Add Company</h2>
      <Form onSubmit={Add_Bank}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formBankName">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                name="bankName"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBranchName">
              <Form.Label>Branch Name</Form.Label>
              <Form.Control
                type="text"
                name="branchName"
                value={branchName}
                onChange={(e) => setBranchName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formAccountNo">
              <Form.Label>Account Number</Form.Label>
              <Form.Control
                type="text"
                name="accountNo"
                value={accountNo}
                onChange={(e) => setAccountNo(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formIfscCode">
              <Form.Label>IFSC Code</Form.Label>
              <Form.Control
                type="text"
                name="ifscCode"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formAccountHolderName">
              <Form.Label>Account Holder Name</Form.Label>
              <Form.Control
                type="text"
                name="accountHolderName"
                value={accountHolderName}
                onChange={(e) => setAccountHolderName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formWireNo">
              <Form.Label>Wire Number</Form.Label>
              <Form.Control
                type="text"
                name="wireNo"
                value={wireNo}
                onChange={(e) => setWireNo(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formInsuranceAmount">
              <Form.Label>Insurance Amount</Form.Label>
              <Form.Control
                type="text"
                name="insuranceAmount"
                value={insuranceAmount}
                onChange={(e) => setInsuranceAmount(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formFirstPayout">
              <Form.Label>First Payout Date</Form.Label>
              <Form.Control
                type="date"
                name="firstPayout"
                value={firstPayout}
                onChange={(e) => setFirstPayout(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formDue">
              <Form.Label>Due Date</Form.Label>
              <Form.Control
                type="date"
                name="due"
                value={due}
                onChange={(e) => setDue(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <div className="submit-container">
          <Button variant="primary" type="submit" className="submit-button">
            Submit
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default AddBank;
