import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "./AddSales.scss";

const EditSales = () => {
  const { salesId } = useParams();
  const [clientId, setClientId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [products, setProducts] = useState("");
  const [unit, setUnit] = useState("");
  const [discount, setDiscount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [dateOfSales, setDateOfSales] = useState("");
  const [monthlyPayoutAmount, setMonthlyPayoutAmount] = useState("");
  const [clients, setClients] = useState([]);
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDropdownData();
    fetchSalesDetails();
  }, []);

  const fetchDropdownData = async () => {
    try {
      const { data } = await axios.post("https://lunarsenterprises.com:4000/aims/dropdown");
      if (data.result) {
        setClients(data.clientlist);
        setCompanies(data.companylist);
      } else {
        alert("Failed to fetch dropdown data");
      }
    } catch (error) {
      console.error("Error fetching dropdown data:", error);
      alert("An error occurred while fetching dropdown data. Please try again.");
    }
  };

  const fetchSalesDetails = async () => {
    try {
      const { data } = await axios.post("https://lunarsenterprises.com:4000/aims/list-sales", {
        sales_id: salesId,
      });
      const saleDetails = data.list.find((sale) => sale.sales_id === parseInt(salesId));
      if (saleDetails) {
        setClientId(saleDetails.client_id);
        setCompanyId(saleDetails.company_id);
        setProducts(saleDetails.sales_product);
        setUnit(saleDetails.sales_unit);
        setDiscount(saleDetails.sales_discount);
        setTotalAmount(saleDetails.sales_total_amount);
        setDateOfSales(saleDetails.sales_date);
        setMonthlyPayoutAmount(saleDetails.sales_monthly_payment_amount);
      } else {
        alert("Failed to fetch sale details.");
      }
    } catch (error) {
      console.error("Error fetching sale details:", error);
      alert("An error occurred while fetching sale details. Please try again.");
    }
  };

  const updateSales = async (event) => {
    event.preventDefault();
    try {
      const { data } = await axios.post("https://lunarsenterprises.com:4000/aims/edit-sales", {
        sales_id: salesId,
        client_name: clientId,
        company_name: companyId,
        product: products,
        unit: unit,
        discount: discount,
        total_amount: totalAmount,
        sales_date: dateOfSales,
        monthly_payment_amount: monthlyPayoutAmount,
      });

      alert(data.message);
      navigate("/sales");
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while updating the sales record. Please try again.");
    }
  };

  return (
    <Container className="edit-sales-container">
      <h2 className="edit-sales-title">Edit Sales</h2>
      <Form onSubmit={updateSales}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formClientName">
              <Form.Label>Client Name</Form.Label>
              <Form.Control
                as="select"
                name="clientId"
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
                required
              >
                <option value="">Select Client</option>
                {clients.map((client) => (
                  <option key={client.client_id} value={client.client_id}>
                    {client.client_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formCompanyName">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                as="select"
                name="companyId"
                value={companyId}
                onChange={(e) => setCompanyId(e.target.value)}
                required
              >
                <option value="">Select Company</option>
                {companies.map((company) => (
                  <option key={company.c_id} value={company.c_id}>
                    {company.c_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formProducts" className="mb-4">
              <Form.Label>Products</Form.Label>
              <Form.Control
                type="text"
                name="products"
                value={products}
                onChange={(e) => setProducts(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formUnit" className="mb-4">
              <Form.Label>Unit</Form.Label>
              <Form.Control
                type="text"
                name="unit"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDiscount" className="mb-4">
              <Form.Label>Discount</Form.Label>
              <Form.Control
                type="text"
                name="discount"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                required
              />
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="formTotalAmount" className="mb-4">
              <Form.Label>Total Amount</Form.Label>
              <Form.Control
                type="text"
                name="totalAmount"
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formDateOfSales" className="mb-4">
              <Form.Label>Date of Sales</Form.Label>
              <Form.Control
                type="date"
                name="dateOfSales"
                value={dateOfSales}
                onChange={(e) => setDateOfSales(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formMonthlyPayoutAmount" className="mb-4">
              <Form.Label>Monthly Payout Amount</Form.Label>
              <Form.Control
                type="text"
                name="monthlyPayoutAmount"
                value={monthlyPayoutAmount}
                onChange={(e) => setMonthlyPayoutAmount(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Button variant="primary" type="submit" className="mt-4">
          Update
        </Button>
      </Form>
    </Container>
  );
};

export default EditSales;
