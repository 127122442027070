import React, { useState } from 'react';
import './Email.scss'

const Paragraph = ({ initialContent }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(initialContent);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    // Perform any save/update action here with the editedContent state
  };

  const handleChange = (e) => {
    setEditedContent(e.target.value);
  };

  return (
    <div className='formates'>
      <div className='formate-heading'>
        <h6>Welcome Format</h6>
        <input type="checkbox" />
      </div>

      <div className='para-edit'>
        {isEditing ? (
          <textarea
            value={editedContent}
            onChange={handleChange}
          />
        ) : (
          <p>{editedContent}</p>
        )}
        <i className="fas fa-edit" onClick={handleEditClick}></i>
        {isEditing && <button onClick={handleSaveClick}>Save</button>}
      </div>
    </div>
  );
};

const Email = () => {
  return (
    <>
      <div className="activity-head">
        <h3>Emails/Updates</h3>
      </div>
      <div className='mail-box'>
        <h5>Email Format</h5>
        <div className='mail-formates'>
          <Paragraph initialContent="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda fugiat quam sequi, eum explicabo eius totam nobis architecto vitae consequatur. Doloremque beatae earum sunt illo consectetur excepturi aut et temporibus." />
          <Paragraph initialContent="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda fugiat quam sequi, eum explicabo eius totam nobis architecto vitae consequatur. Doloremque beatae earum sunt illo consectetur excepturi aut et temporibus." />
          <Paragraph initialContent="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda fugiat quam sequi, eum explicabo eius totam nobis architecto vitae consequatur. Doloremque beatae earum sunt illo consectetur excepturi aut et temporibus." />
          <Paragraph initialContent="Lorem ipsum dolor sit, amet consectetur adipisicing elit. Assumenda fugiat quam sequi, eum explicabo eius totam nobis architecto vitae consequatur. Doloremque beatae earum sunt illo consectetur excepturi aut et temporibus." />
        </div>
      </div>
    </>
  );
}

export default Email;
